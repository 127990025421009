var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mf-dialog',{attrs:{"max-width":"1600","title":_vm.title},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.sale && _vm.sale.items)?_c('v-row',{staticClass:"mx-1 mt-n6"},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[(_vm.sale.temporary_churn_period)?_c('v-col',{staticClass:"mx-2",attrs:{"cols":"auto"}},[_c('date-picker',{attrs:{"label":"Data inicio do abono temporário","date":_vm.sale.temporary_churn_period.start_date,"disabled":"","outline":""},on:{"update:date":function($event){return _vm.$set(_vm.sale.temporary_churn_period, "start_date", $event)}}})],1):_vm._e(),(_vm.sale.temporary_churn_period)?_c('v-col',{attrs:{"cols":"auto"}},[_c('date-picker',{attrs:{"label":"Data fim do abono temporário","date":_vm.sale.temporary_churn_period.end_date,"disabled":"","outline":""},on:{"update:date":function($event){return _vm.$set(_vm.sale.temporary_churn_period, "end_date", $event)}}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',{staticClass:"mx-4 mb-0 mt-2"},[_vm._v("Total: "+_vm._s(_vm.total))])])],1),_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('headers-data-table',{attrs:{"headers":_vm.headers,"default-headers":_vm.getDefaultHeadersOptions(),"headers-settings-name":"headers-sale"},on:{"update:headers":function($event){_vm.headers=$event}}})],1)],1)],1)],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"headers":_vm.computedHeaders,"items":_vm.sale.items},scopedSlots:_vm._u([{key:"item.unit_value",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.type === 'sms' ? _vm.parseCurrencySms(item.unit_value) : _vm.parseCurrencyDefault(item.unit_value))+" ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.name || _vm.getProductFromType(item.type).name)+" ")])],1)]}},{key:"item.charge_type",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.type === 'sms' || item.type === 'email' ? (item.charge_type === 'recurring' ? 'Recorrente' : 'Única') : '-')+" ")])],1)]}},{key:"item.is_accumulative",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.type === 'sms' || item.type === 'email' ? (item.is_accumulative ? 'Sim' : 'Não') : '-')+" ")])],1)]}},{key:"item.has_order",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.type === 'mercashop' ? (item.has_order ? 'Sim' : 'Não') : '-')+" ")])],1)]}},{key:"item.implantation_value",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.getProductFromType(item.type).hasSetupCost ? _vm.parseCurrencyDefault(item.implantation_value) : '-')+" ")])],1)]}},{key:"item.total_value",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.parseCurrencyDefault(item.total_value))+" ")])],1)]}},{key:"item.old_total_value",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[(_vm.sale.type === 'discount')?_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.parseCurrencyDefault(item.old_total_value))+" ")]):_c('span',[_vm._v("-")])],1)]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm._f("localeString")(item.quantity,0))+" ")])],1)]}},{key:"item.old_unit_value",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[(_vm.sale.type === 'discount')?_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.type === 'sms' ? _vm.parseCurrencySms(item.old_unit_value) : _vm.parseCurrencyDefault(item.old_unit_value))+" ")]):_c('span',[_vm._v("-")])],1)]}},{key:"item.discount_operation_value",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[(_vm.sale.type === 'discount')?_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('span',{class:item.discount_operation_value > 0 ? 'text-plus' : 'text-minus'},[_c('mf-icon',{attrs:{"size":"15","icon":item.discount_operation_value > 0 ? 'north' : 'south',"color":item.discount_operation_value > 0 ? '#003b02' : '#7c0e0e'}}),_vm._v(" "+_vm._s(_vm.parseCurrencyDefault(item.discount_operation_value))+" ")],1)]):_c('span',[_vm._v("-")])],1)]}},{key:"item.immediate_billing",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.immediate_billing ? 'Sim' : 'Não')+" ")])],1)]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('span',[_vm._v(" "+_vm._s(item.plan || '-')+" ")])])],1)]}},{key:"item.billing_date_periods",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.billing_date_periods ? item.billing_date_periods : '-')+" ")])],1)]}},{key:"item.ambev_incentive_months",fn:function(ref){return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.sale.ambev_incentive ? _vm.sale.ambev_incentive.ambev_incentive_months : 0)+" ")])],1)]}},{key:"item.ambev_incentive_text",fn:function(ref){return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.sale.ambev_incentive ? _vm.sale.ambev_incentive.ambev_incentive_text : '-')+" ")])],1)]}},{key:"item.units",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[(item.units)?_c('v-tooltip',{attrs:{"max-width":"350","top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.showUnits(item.units)[0].name))])]}}],null,true)},_vm._l((_vm.showUnits(item.units)),function(item){return _c('div',{key:item.cnpj},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])}),0):_c('span',[_vm._v(" - ")])],1)],1)]}}],null,true)})],1)],1):_c('v-row',{staticClass:"mx-1",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1),_c('v-row',{staticClass:"mx-1"},[_c('v-col',{attrs:{"cols":"6"}},[_c('mf-button',{attrs:{"color":"error","label":"Sair"},on:{"click":_vm.close}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }