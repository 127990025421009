<template>
  <mf-dialog v-model="dialog" max-width="1600" :title="title">
    <v-row v-if="sale && sale.items" class="mx-1 mt-n6">
      <v-col>
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters justify="end">
              <v-col v-if="sale.temporary_churn_period" class="mx-2" cols="auto">
                <date-picker label="Data inicio do abono temporário" :date.sync="sale.temporary_churn_period.start_date" disabled outline />
              </v-col>
              <v-col v-if="sale.temporary_churn_period" cols="auto">
                <date-picker label="Data fim do abono temporário" :date.sync="sale.temporary_churn_period.end_date" disabled outline />
              </v-col>
              <v-col cols="auto">
                <h2 class="mx-4 mb-0 mt-2">Total: {{ total }}</h2>
              </v-col>
            </v-row>
            <v-row no-gutters justify="end">
              <v-col cols="auto">
                <headers-data-table :headers.sync="headers" :default-headers="getDefaultHeadersOptions()" headers-settings-name="headers-sale" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table class="mt-4" :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }" :headers="computedHeaders" :items="sale.items">
          <template v-slot:[`item.unit_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'sms' ? parseCurrencySms(item.unit_value) : parseCurrencyDefault(item.unit_value) }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.name || getProductFromType(item.type).name }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.charge_type`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'sms' || item.type === 'email' ? (item.charge_type === 'recurring' ? 'Recorrente' : 'Única') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.is_accumulative`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'sms' || item.type === 'email' ? (item.is_accumulative ? 'Sim' : 'Não') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.has_order`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'mercashop' ? (item.has_order ? 'Sim' : 'Não') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.implantation_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ getProductFromType(item.type).hasSetupCost ? parseCurrencyDefault(item.implantation_value) : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.total_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ parseCurrencyDefault(item.total_value) }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.old_total_value`]="{ item }">
            <v-flex>
              <v-layout v-if="sale.type === 'discount'" justify-center align-center>
                {{ parseCurrencyDefault(item.old_total_value) }}
              </v-layout>
              <span v-else>-</span>
            </v-flex>
          </template>

          <template v-slot:[`item.quantity`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.quantity | localeString(0) }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.old_unit_value`]="{ item }">
            <v-flex>
              <v-layout v-if="sale.type === 'discount'" justify-center align-center>
                {{ item.type === 'sms' ? parseCurrencySms(item.old_unit_value) : parseCurrencyDefault(item.old_unit_value) }}
              </v-layout>
              <span v-else>-</span>
            </v-flex>
          </template>

          <template v-slot:[`item.discount_operation_value`]="{ item }">
            <v-flex>
              <v-layout v-if="sale.type === 'discount'" justify-center align-center>
                <span :class="item.discount_operation_value > 0 ? 'text-plus' : 'text-minus'">
                  <mf-icon
                    size="15"
                    :icon="item.discount_operation_value > 0 ? 'north' : 'south'"
                    :color="item.discount_operation_value > 0 ? '#003b02' : '#7c0e0e'"
                  ></mf-icon>
                  {{ parseCurrencyDefault(item.discount_operation_value) }}
                </span>
              </v-layout>
              <span v-else>-</span>
            </v-flex>
          </template>

          <template v-slot:[`item.immediate_billing`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.immediate_billing ? 'Sim' : 'Não' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.plan`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <span> {{ item.plan || '-' }} </span>
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.billing_date_periods`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.billing_date_periods ? item.billing_date_periods : '-' }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.ambev_incentive_months`]="{}">
            <v-flex>
              <v-layout justify-center align-center>
                {{ sale.ambev_incentive ? sale.ambev_incentive.ambev_incentive_months : 0 }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.ambev_incentive_text`]="{}">
            <v-flex>
              <v-layout justify-center align-center>
                {{ sale.ambev_incentive ? sale.ambev_incentive.ambev_incentive_text : '-' }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.units`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <v-tooltip v-if="item.units" max-width="350" top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ showUnits(item.units)[0].name }}</span>
                  </template>
                  <div v-for="item in showUnits(item.units)" :key="item.cnpj">
                    <span> {{ item.name }} </span>
                  </div>
                </v-tooltip>
                <span v-else> - </span>
              </v-layout>
            </v-flex>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-else justify="center" class="mx-1">
      <v-col cols="auto">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
    <v-row class="mx-1">
      <v-col cols="6">
        <mf-button color="error" label="Sair" @click="close"></mf-button>
      </v-col>
    </v-row>
  </mf-dialog>
</template>

<script>
import { QUERY_GET_SALE_BY_ID } from '@/modules/retailers/graphql'
import { tableHeaders } from '@/constants/sales'
import { getSaleTypeByValue } from '@/constants/salesTypes'
import { parseCurrency, headers, productsInformation } from '@/mixin'
import { cloneDeep } from 'lodash'
export default {
  components: {
    DatePicker: () => import('@/components/calendar/DatePicker'),
    HeadersDataTable: () => import('@/components/atomic-components/molecules/HeadersDataTable')
  },
  mixins: [parseCurrency, headers, productsInformation],
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    saleId: {
      type: String,
      default: () => ''
    },
    units: {
      type: Array
    }
  },
  data: () => ({
    sale: null,
    headers: []
  }),
  computed: {
    title() {
      if (this.sale) return 'Detalhes ' + this.modalTitle(this.sale.type)
      return ''
    },
    darkMode: {
      get() {
        return localStorage.getItem('dark_theme') === 'true'
      }
    },
    total() {
      let totalValue = 0
      this.sale.items.map(item => (totalValue += item?.total_value))
      return this.parseCurrencyDefault(totalValue)
    }
  },
  apollo: {
    sales: {
      query: QUERY_GET_SALE_BY_ID,
      fetchPolicy: 'network-only',
      variables() {
        return { sale_id: this.saleId }
      },
      update({ sale }) {
        this.sale = sale
        let headers = tableHeaders.new_sale
        if (sale?.type === 'temporary_churn' || sale?.type === 'revert_temporary_churn' || sale?.type === 'discount') {
          headers = headers.filter(item => item.value !== 'units')
        }
        this.headers = cloneDeep(headers)
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  methods: {
    showUnits(units) {
      const arrUnits = units.split(',').map(item => item.replace(/\s/g, ''))
      const uniqueUnitsSet = new Set(arrUnits)
      const seenCNPJs = new Set()
      const unitsLinked = this.units.filter(item => {
        if (uniqueUnitsSet.has(item.cnpj) && !seenCNPJs.has(item.cnpj)) {
          seenCNPJs.add(item.cnpj)
          return true
        }
        return false
      })
      return unitsLinked
    },
    getDefaultHeadersOptions() {
      return cloneDeep(this.headers)
    },
    modalTitle(saleType) {
      return getSaleTypeByValue(saleType)?.label ?? ''
    },
    close() {
      this.$emit('close')
      this.sale = null
    },
    mapIcon(type) {
      const mapIcons = {
        app: 'mdi-cellphone-text',
        cbm: 'mdi-archive',
        ecommerce: 'mdi-briefcase-plus',
        email: 'mdi-email-arrow-right-outline',
        sms: 'mdi-message-alert-outline',
        whatsapp: 'mdi-whatsapp'
      }

      return mapIcons[type]
    }
  }
}
</script>

<style scoped>
.text-minus {
  color: #7c0e0e;
  border-radius: 10px;
  padding: 8px;
  background-color: rgba(185, 17, 17, 0.2);
}
.text-plus {
  color: #003b02;
  border-radius: 10px;
  padding: 8px;
  background-color: rgba(73, 216, 7, 0.5);
}
</style>
